import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n); // 全局挂载

export const i18n = new VueI18n({
  locale: localStorage.getItem("locale") || "zh", // 从localStorage中获取 默认英文
  messages: {
    zh: require("./lan/zh"), // 中文语言包
    en: require("./lan/en"), // 英文语言包
    zhf: require("./lan/zhf") // 繁体语言包

  }
});

// // 获取当前语言设置
// let currentLanguage = (localStorage.getItem('lang') || i18n.locale).toLocaleUpperCase();
// console.log("currentLanguage", currentLanguage)


// // 设置页面标题
// function setTitle(i18nInstance, locale) {
//   const titleKey = 'h.header.title'; // 假设这是你的国际化 key
//   const title = i18nInstance.t(titleKey);
//   document.title = title;
// }

// // 初始化时设置标题
// setTitle(i18n, currentLanguage);


export default i18n;