let t = null;
const utils = {
  install(Vue, options) {
    Vue.prototype.utils = {
      IsPC() {
        let userAgentInfo = navigator.userAgent;
        let Agents = [
          "Android",
          "iPhone",
          "SymbianOS",
          "Windows Phone",
          "iPad",
          "iPod",
        ];
        let flag = true;
        for (let v = 0; v < Agents.length; v++) {
          if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
          }
        }
        return flag;
      },
      formatField(obj) {
        const specialSymbols = ["-_-"]; //处理特殊情况  label必须放前面
        let newObj = {};
        for (let key in obj) {
          if (Array.isArray(obj[key])) {
            specialSymbols.forEach((item) => {
              console.log(obj[key]);

              if (obj[key].some((str) => str.toString().includes(item))) {
                const arr = obj[key].map((v) => {
                  return v.split(item)[1];
                });
                newObj[key] = arr.join(",");
              } else {
                newObj[key] = obj[key].join(",");
              }
            });
          } else {
            newObj[key] = obj[key];
          }
        }
        return newObj;
      },
      debounce(fn, delay) {
        if (t !== null) {
          clearTimeout(t);
        }
        t = setTimeout(() => {
          fn();
        }, delay);
      },
      uniqueObjectsByEntireObject(arr) {
        /**
         * 利用Set数据结构的唯一性，结合JSON.stringify()将对象转换为字符串进行去重。
         * 注意：这种方法假设对象的序列化字符串是可预测且能正确反映对象相等性的。
         */
        return [...new Set(arr.map((item) => JSON.stringify(item)))].map(
          (str) => JSON.parse(str)
        );
      },
      calculateAge(birthdayStr) {
        const birthday = new Date(birthdayStr);
        const today = new Date();

        // 首先检查生日是否在未来
        if (birthday > today) {
          return {
            error: "Birthdate is in the future.",
          };
        }

        // 计算完整年数
        let years = today.getFullYear() - birthday.getFullYear();

        // 调整月份和日期
        let months = today.getMonth() - birthday.getMonth();
        let days = today.getDate() - birthday.getDate();

        // 如果月份或天数不够，则减少一年，并重新计算月份和天数
        if (months < 0 || (months === 0 && days < 0)) {
          years--;
          months += 12;
        }

        // 如果月份不够，则减少一个月，并重新计算天数
        while (days < 0) {
          months--;
          const daysInPreviousMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            0
          ).getDate();
          days += daysInPreviousMonth;
        }

        // 确保月份正确
        if (months < 0) {
          years--;
          months += 12;
        }

        // 计算周数和剩余天数
        let weeks = Math.floor(days / 7);
        let daysRemaining = days % 7;

        // 特殊处理当天的情况
        if (
          weeks === 0 &&
          daysRemaining === 0 &&
          birthday.toDateString() === today.toDateString()
        ) {
          weeks = 0;
          daysRemaining = 1;
        }

        // 构造返回对象
        let result = {};
        if (years > 0) {
          result = {
            岁: years,
            月: months,
          };
        } else if (months > 0) {
          result = {
            月: months,
            周: weeks,
          };
        } else if (weeks >= 0) {
          result = {
            周: weeks,
            天: daysRemaining,
          };
        }
        let arr = Object.entries(result);
        let ageType = arr[0][0];
        let age = arr[0][1];
        let sampleAgeSecType = arr[1][0];
        let sampleAgeSec = arr[1][1];
        let obj = {
          ageType,
          age,
          sampleAgeSecType,
          sampleAgeSec,
        };

        return obj;
      },
      nametm(value) {
        if (!value) return '--'
        if (value.length === 2) {
          return value.substring(0, 1) + '*'
        } else if (value.length === 3) {
          return value.substring(0, 1) + '*' + value.substring(2, 3)
        } else if (value.length > 3) {
          return (value.substring(0, 1) + '**' + value.substring(value.length - 1, value.length)) //截取第一个和大于第4个字符
        } else {
          return value
        }
      },
      idcardtm(value) {
        if (value) {
          return value.replace(/^(.{3})(?:\d+)(.{4})$/, "$1****$2")
        } else {
          return '--'
        }
      },
      phonetm(value) {
        if (value) {
          return value.replace(/^(.{3})(?:\d+)(.{4})$/, "$1****$2")
        } else {
          return '--'
        }
      },

    };
  },
};
export default utils;