import router from './index'
import {
  i18n
} from '../i18n'

router.beforeEach((to, from, next) => {

  console.log("router", to, from)
  if (to.query.lang === 'en') {
    localStorage.setItem("lang", 'en');
    i18n.locale = "en";
  } else {
    localStorage.setItem("lang", 'zh');
    i18n.locale = "zh";
  }
  next()

})