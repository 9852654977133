export const h = {
    lang: '繁體中文',
    title: {
        text: "Sign in",
    },
    section1: {
        text: "全球首個Web3",
        text1: "基於JuCoin、JuCoin Labs、JuChain生熊系统打造",
        text2: "立即购买仅需1399美元",
    },
    section2: {
        text: "100亿+",
        text1: "美元交易量",
        text2: "全球用户",
        text3: "日活用户",
        text4: "覆盖国家",
    },
    section3: {
        text: "JuOne核心模块及",
        text1: "功能介绍",
        text2: "完善的上市規割及經濟模型",
        text3: "Comprehensive listing regulations and economic models",
        text4: "豐厚的前期参與獎勵",
        text5: "Generous early participation rewards",
        text6: "持續社區活動及技術駭客馬拉松活動",
        text7: "Continuous community activities and technology hacking marathon events",
        text8: "豐富的Web3應用程式預装",
        text9: "Rich Web3 application pre installed",
    },
    section4: {
        text: "JuOne",
        text1: "介绍",
        text2: "原生多鏈支持",
        text3: "廣告與任務激勵系統",
        text4: "JUStore應用商店",
        text5: "多場景協同功能",
        text6: "可信硬體及區塊鏈網路",
        text7: "AI智能服務",
        text8: "DEPIN網路支持",
        text9: "商業專案發射器",
        text10: "數字身份舆隱私管理",
    },
    section5: {
        text: "以Web3手機為",
        text1: "核心载體",
        text2: "打造全球領先的Web3多方共赢生熊",
    },
    section6: {
        text: "JuOne發展規劃",
        text1: "2024年 ",
        text2: "Q4-2025年Q1",
        text3: "集中佈局束南亞、北美、欧洲等Web3热點區域完成第一階段銷售10萬臺的日標與BNB Chain、Solana、Polygon等主流公鏈達成合作上線JuOne平豪幣",
        text4: "2025年",
        text5: "Q2-Q4",
        text6: "與DEPINex專案展开合作完成第二階段销售100萬壹的目標在東南平、欧洲和北美建立區域運營中心搭建Web3物联網生熊",
        text7: "2026年",
        text8: "Q1-Q2",
        text9: "推出Web3應用商城支持更多DApp接入JuOne生熊推動更多公鏈的接入，形成多鏈生熊的全面互通骏佈JuOne手機2.0版本",
        text10: "2026年",
        text11: "Q3-Q4",
        text12: "支持更多DEPINex專案的接入生熊應用擴展,富用戶在Web3中的增值體驗JuOne手機進入超過50個國家市場,服务用戶超3000萬推動更多公的接入形成多鏈生能的全面互通",
    },

    foot: {
        text: "服務條款",
        text1: "隱私政策",
        text2: "Cookie 政策",
    },
};