export const h = {
  lang: 'English',
  title: {
    text: "Sign in",
  },
  section1: {
    text: "The world's first Web3",
    text1: "Based on JuCoin, JuCoin Labs, and JuChain's Bear System",
    text2: "Buy now for only $1399",
  },
  section2: {
    text: "10 billion+",
    text1: "USD Volume",
    text2: "Global Users",
    text3: "Daily active users",
    text4: "Covered countries",
  },
  section3: {
    text: "JuOne Core Modules & ",
    text1: "Features",
    text2: "Complete listing rules and economic models",
    text3: "",
    text4: "Generous early participation rewards",
    text5: "",
    text6: "Continuous community activities and technical hacker marathon events",
    text7: "",
    text8: "Rich Web3 application pre installed",
    text9: "",
  },
  section4: {
    text: "JuOne",
    text1: " Introduce",
    text2: "Native multi-chain support",
    text3: "Advertising and Task Incentive System",
    text4: "JUStore App Store",
    text5: "Multi-scene collaboration function",
    text6: "Trusted Hardware and Blockchain Network",
    text7: "AI Intelligent Services",
    text8: "DEPIN Network Support",
    text9: "Business Project Launcher",
    text10: "Digital Identity and Privacy Management",
  },
  section5: {
    text: "Web3 mobile phone as",
    text1: " The core carrier",
    text2: "Global Leading Web3 Win-Win Ecosystem",
  },
  section6: {
    text: "JuOne Development Plan",
    text1: "2024",
    text2: "Q4-Q1 2025",
    text3: "Focus on Web3 hotspots in South Asia, North America, Europe, etc. Complete the first phase of sales of 100,000 units per day. Cooperate with BNB Chain, Solana, Polygon and other mainstream public chains to launch JuOne Pinghao Coin",
    text4: "2025",
    text5: "Q2-Q4",
    text6: "Cooperate with DEPINex project to achieve the second phase sales target of 1 million yuan. Establish regional operation centers in Southeast, Europe and North America to build Web3 IoT network.",
    text7: "2026",
    text8: "Q1-Q2",
    text9: "Launched Web3 application store to support more DApps to access JuOne, promoted the access of more public chains, and formed a comprehensive interconnection of multiple chains. Released JuOne mobile 2.0 version",
    text10: "2026",
    text11: "Q3-Q4",
    text12: "Support more DEPINex projects to access the app and expand users' value-added experience in Web3. JuOne mobile phone has entered more than 50 national markets, serving more than 30 million users. Promote more public access to form a comprehensive interconnection of multi-chain capabilities.",
  },
  foot: {
    text: "Terms of Service",
    text1: "privacy policy",
    text2: "Cookie Policy",
  },
};