import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./router/permission";
// import "amfe-flexible";
import './assets/css/index.css'
import utils from "@/utils/utils.js";

import {
  i18n
} from './i18n/index'

Vue.config.productionTip = false;


Vue.use(utils)

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");