<template>
  <div class="language-selector" ref="selector">
    <!-- 触发下拉菜单的按钮 -->
    <div @click="toggleDropdown" class="dropdown-trigger">
      <img class="lang-img" src="../../assets/lang.png" alt="Language" />
      <span class="text">{{ currentLang }}</span>
      <img class="lang-img" src="../../assets/lang1.png" alt="Dropdown" />
    </div>

    <!-- 下拉菜单 -->
    <ul v-if="isDropdownVisible" class="dropdown-menu">
      <li @click="changeLanguage('zh')">简体中文</li>
      <li @click="changeLanguage('zhf')">繁体中文</li>
      <li @click="changeLanguage('en')">English</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDropdownVisible: false,
      availableLanguages: ["zh", "en", "zhf"],
    };
  },
  computed: {
    // 计算当前语言
    currentLang() {
      const lang = this.$i18n.locale;
      if (lang === "zh") return "简体中文";
      if (lang === "en") return "English";
      if (lang === "zhf") return "繁體中文";
      return "Language";
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
      if (this.isDropdownVisible) {
        // 添加全局点击监听器
        document.addEventListener("click", this.handleClickOutside);
      } else {
        // 移除全局点击监听器
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    handleClickOutside(event) {
      // 如果点击的是组件外，则关闭下拉菜单
      if (!this.$refs.selector?.contains(event.target)) {
        this.isDropdownVisible = false;
        // 移除全局点击监听器
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    changeLanguage(lang) {
      if (this.availableLanguages.includes(lang)) {
        this.$i18n.locale = lang; // 改变vue-i18n的语言
        localStorage.setItem("lang", lang);
        this.isDropdownVisible = false; // 关闭下拉菜单
      }
    },
  },
};
</script>

<style lang="less" scoped>
.language-selector {
  //   position: relative;
  opacity: 0.8;
  .dropdown-trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.16rem;
    color: #ffffff;
    line-height: 0.24rem;
    .text {
      margin: 0 0.05rem;
    }
    .lang-img {
      width: 0.18rem;
      height: 0.18rem;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgb(1, 1, 1);
    list-style-type: none;
    padding: 0;
    margin: 0.04rem 0 0 0;
    min-width: 1rem;
    box-shadow: 0 0.08rem 0.16rem rgba(0, 0, 0, 0.2);
    z-index: 1;
    font-size: 0.16rem;
    color: #ffffff;
    line-height: 0.24rem;
    border-radius: 5px;

    li {
      padding: 0.08rem 0.16rem;
      cursor: pointer;
      &:hover {
        background-color: #f1f1f1;
        color: #000;
      }
    }
  }
}
</style>
