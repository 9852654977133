<style lang="less"></style>
<template>
  <div id="app" style="height: 100vh">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",

  data() {
    return {};
  },
  mounted() {
    // this.adjustScale();
    // window.addEventListener("resize", this.adjustScale);
    this.setRootFontSize();
    window.addEventListener("resize", () => {
      this.setRootFontSize();
    });
  },
  methods: {
    //语言切换
    changeLangEvent(language) {
      //变成大写字母
      language = language.toUpperCase();
      if (language == "EN") {
        localStorage.setItem("locale", "en");
        this.$i18n.locale = "en";
        localStorage.setItem("lang", language);
        // location.reload(); //重新加载
      } else if (language == "ZH") {
        localStorage.setItem("locale", "zh");
        this.$i18n.locale = "zh";
        localStorage.setItem("lang", language);
        // location.reload();
      }
    },
    //适配PC端
    setRootFontSize() {
      const baseWidth = this.utils.IsPC() ? 1920 : 375;
      const baseFontSize = this.utils.IsPC() ? 100 : 16;

      // 获取当前窗口宽度
      const currentWidth =
        document.documentElement.clientWidth || document.body.clientWidth;

      // 计算缩放比例
      let scale = currentWidth / baseWidth;

      // 设置最大和最小缩放限制（可选）
      const minScale = 0.5; // 最小缩放比例
      const maxScale = 2; // 最大缩放比例

      scale = Math.min(Math.max(scale, minScale), maxScale);
      // 根据缩放比例计算字体大小
      const rootFontSize = baseFontSize * scale;
      console.log("rootFontSize", rootFontSize);
      // 应用计算后的字体大小到根元素
      document.querySelector("html").style.fontSize = `${rootFontSize}px`;
    },
    adjustScale() {
      const maxWidth = 1920; // 假设PC端最大宽度为1920px
      const currentWidth = window.innerWidth;

      if (currentWidth < 677) {
        const scale = currentWidth / maxWidth;
        document.body.style.transformOrigin = "top left";
        document.body.style.transform = `scale(${scale})`;
        let fontSize = maxWidth / 16;
        document.getElementsByTagName("html")[0].style["font-size"] =
          fontSize + "px";
      } else {
        document.body.style.transform = "";
      }
    },
  },
};
</script>
