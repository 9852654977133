<style lang="less">
.lang {
  position: absolute;
  right: 0.35rem;
  top: 0.4rem;
}
.head {
  position: relative;
}
</style>
<template>
  <div class="home">
    <main>
      <section class="title">
        <header class="head">
          <div class="left">
            <img src="../assets/icon.png" alt="" />
          </div>
          <div class="right">
            <span>Sign in</span>
            <img src="../assets/login.png" alt="" />
          </div>
          <lang class="lang" />
        </header>
        <div class="box">
          <div class="box-left">
            <div class="text1">The world's first Web3</div>
            <div class="text2">{{ $t("h.section1.text") }}</div>
            <div class="text3">
              {{ $t("h.section1.text1") }}
            </div>
            <div class="text4">
              <div>Built on the JuCoin, JuCoin Labs, JuChain ecosystems</div>
              <img src="../assets/jiantou.png" alt="" />
            </div>

            <div class="text5">{{ $t("h.section1.text2") }}</div>
          </div>
          <div class="box-right">
            <img class="phone" src="../assets/box-phone.png" alt="" />
            <img class="phone-bg" src="../assets/box-phone-bg.png" alt="" />
          </div>
        </div>
      </section>
      <section class="nav">
        <div class="box">
          <div>
            <span class="up">{{ $t("h.section2.text") }}</span>
            <span class="down">{{ $t("h.section2.text1") }}</span>
          </div>
          <div>
            <span class="up">12,000,000+</span>
            <span class="down">{{ $t("h.section2.text2") }}</span>
          </div>
          <div>
            <span class="up">200,000+</span>
            <span class="down">{{ $t("h.section2.text3") }}</span>
          </div>
          <div>
            <span class="up">30+</span>
            <span class="down">{{ $t("h.section2.text4") }}</span>
          </div>
        </div>
      </section>
      <section class="introduce">
        <div class="star">
          <img class="star-img" src="../assets/star.png" alt="" />
        </div>
        <div class="text">Introduction to JuOne Core Modules and Functions</div>
        <div class="ctrl">
          <div class="text1">
            {{ $t("h.section3.text") }}<span>{{ $t("h.section3.text1") }}</span>
          </div>
          <div class="ctrl-btn">
            <div class="ctrl-btn-img custom-prev" @click="prevSlide">
              <img src="../assets/Frame.png" alt="" />
            </div>
            <div class="ctrl-btn-img img1 custom-next" @click="nextSlide">
              <img src="../assets/Frame.png" alt="" />
            </div>
          </div>
        </div>
        <div class="swiper">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide>
              <div class="swiperBox">
                <div class="box">
                  <img src="../assets/swiper1.png" alt="" />
                  <span class="up">{{ $t("h.section3.text2") }}</span>
                  <span class="down">{{ $t("h.section3.text3") }}</span>
                  <div class="btn">Learn More</div>
                </div>
                <div class="box">
                  <img src="../assets/swiper2.png" alt="" />
                  <span class="up">{{ $t("h.section3.text4") }}</span>
                  <span class="down">{{ $t("h.section3.text5") }}</span>
                  <div class="btn">Learn More</div>
                </div>
                <div class="box">
                  <img src="../assets/swiper3.png" alt="" />
                  <span class="up">{{ $t("h.section3.text6") }}</span>
                  <span class="down">{{ $t("h.section3.text7") }}</span>
                  <div class="btn">Learn More</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide
              ><div class="swiperBox">
                <div class="box">
                  <img src="../assets/swiper4.png" alt="" />
                  <span class="up">{{ $t("h.section3.text8") }}</span>
                  <span class="down">{{ $t("h.section3.text9") }}</span>
                  <div class="btn">Learn More</div>
                </div>
              </div></swiper-slide
            >
          </swiper>
        </div>
      </section>
      <section class="introduce1">
        <div class="star">
          <img class="star-img" src="../assets/star.png" alt="" />
        </div>
        <div class="text">Introduction to JuOne</div>
        <div class="ctrl">
          <div class="text1">
            JuOne<span>{{ $t("h.section4.text1") }}</span>
          </div>
        </div>
        <div class="groupBox">
          <div class="box">
            <img src="../assets/group1.png" alt="" />
            <span>{{ $t("h.section4.text2") }}</span>
          </div>
          <div class="box">
            <img src="../assets/group2.png" alt="" />
            <span>{{ $t("h.section4.text3") }}</span>
          </div>
          <div class="box">
            <img src="../assets/group3.png" alt="" />
            <span>{{ $t("h.section4.text4") }}</span>
          </div>
          <div class="box">
            <img src="../assets/group4.png" alt="" />
            <span>{{ $t("h.section4.text5") }}</span>
          </div>
          <div class="box">
            <img src="../assets/group5.png" alt="" />
            <span>{{ $t("h.section4.text6") }}</span>
          </div>
          <div class="box">
            <img src="../assets/group6.png" alt="" />
            <span>{{ $t("h.section4.text7") }}</span>
          </div>
          <div class="box">
            <img src="../assets/group7.png" alt="" />
            <span>{{ $t("h.section4.text8") }}</span>
          </div>
          <div class="box">
            <img src="../assets/group8.png" alt="" />
            <span>{{ $t("h.section4.text9") }}</span>
          </div>
          <div class="box">
            <img src="../assets/group9.png" alt="" />
            <span>{{ $t("h.section4.text10") }}</span>
          </div>
        </div>
        <div class="text5">{{ $t("h.section1.text2") }}</div>

        <img class="phone2" src="../assets/phone2.png" alt="" />
      </section>
      <section class="linkBrand">
        <div class="linkBrand-title">
          <div class="star">
            <img class="star-img" src="../assets/star.png" alt="" />
          </div>
          <div class="text">Using Web3 mobile phones as the core carrier</div>
          <div class="ctrl">
            <div class="text1">
              {{ $t("h.section5.text")
              }}<span>{{ $t("h.section5.text1") }}</span>
            </div>
          </div>
          <div class="text1">{{ $t("h.section5.text2") }}</div>
          <img class="pinpai" src="../assets/pinpai.png" alt="" />
        </div>
      </section>
      <section class="introduce introduce4">
        <div class="star">
          <img class="star-img" src="../assets/star.png" alt="" />
        </div>
        <div class="text">JuOne Development Plan</div>
        <div class="ctrl">
          <div class="text1">
            <span>{{ $t("h.section6.text") }}</span>
          </div>
          <div class="ctrl-btn">
            <div class="ctrl-btn-img custom-prev" @click="prevSlide">
              <img src="../assets/Frame.png" alt="" />
            </div>
            <div class="ctrl-btn-img img1 custom-next" @click="nextSlide">
              <img src="../assets/Frame.png" alt="" />
            </div>
          </div>
        </div>
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide>
            <div class="jindu">
              <div class="jindu-box">
                <div class="jindu-top">
                  <img class="point" src="../assets/point.png" alt="" />
                  <div class="line"></div>
                </div>
                <div class="jindu-main">
                  <span class="text1">{{ $t("h.section6.text1") }}</span>
                  <span class="text2">{{ $t("h.section6.text2") }}</span>
                  <span class="text3" :title="$t('h.section6.text3')">{{
                    $t("h.section6.text3")
                  }}</span>
                </div>
              </div>
              <div class="jindu-box">
                <div class="jindu-top">
                  <img class="point" src="../assets/point.png" alt="" />
                  <div class="line"></div>
                </div>
                <div class="jindu-main">
                  <span class="text1">{{ $t("h.section6.text4") }}</span>
                  <span class="text2">Q2-Q4</span>
                  <span class="text3" :title="$t('h.section6.text6')">{{
                    $t("h.section6.text6")
                  }}</span>
                </div>
              </div>
              <div class="jindu-box">
                <div class="jindu-top">
                  <img class="point" src="../assets/point.png" alt="" />
                  <div class="line"></div>
                </div>
                <div class="jindu-main">
                  <span class="text1">{{ $t("h.section6.text7") }}</span>
                  <span class="text2">Q1-Q2</span>
                  <span class="text3" :title="$t('h.section6.text9')">{{
                    $t("h.section6.text9")
                  }}</span>
                </div>
              </div>
              <div class="jindu-box">
                <div class="jindu-top">
                  <img class="point" src="../assets/point.png" alt="" />
                  <div class="line"></div>
                </div>
                <div class="jindu-main">
                  <span class="text1">{{ $t("h.section6.text10") }}</span>
                  <span class="text2">Q3-Q4</span>
                  <span class="text3" :title="$t('h.section6.text12')">{{
                    $t("h.section6.text12")
                  }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </section>
    </main>
    <footer>
      <div class="box1">
        <img src="../assets/icon.png" alt="" />
        <div class="tiaokuan">
          <span>{{ $t("h.foot.text") }}</span>
          <span>{{ $t("h.foot.text1") }}</span>
          <span>{{ $t("h.foot.text2") }}</span>
        </div>
      </div>
      <div class="box2">
        <a href="https://x.com/JUONEGlobal" target="_blank"
          ><img src="../assets/Button.png" alt=""
        /></a>
        <a href="https://t.me/JUONEGlobalCommunity" target="_blank"
          ><img src="../assets/Button-3.png" alt=""
        /></a>
        <a href="https://medium.com/@JUONEAI" target="_blank"
          ><img src="../assets/Button-5.png" alt=""
        /></a>
        <a href="https://www.youtube.com/@JUONEGlobal" target="_blank"
          ><img src="../assets/Button-6.png" alt=""
        /></a>
        <a href="https://juones-organization.gitbook.io/juone/" target="_blank"
          ><img src="../assets/Button-7.png" alt=""
        /></a>
      </div>
      <div class="box3">© 2024 JuOne , inc.</div>
    </footer>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import lang from "./component/lang.vue";
export default {
  name: "HomeView",
  components: {
    swiper,
    swiperSlide,
    lang,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: "1",
        spaceBetween: 50,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".custom-next",
          prevEl: ".custom-prev",
        },
      },
    };
  },
  methods: {
    prevSlide() {
      this.$refs.mySwiper.swiper.slidePrev();
    },
    nextSlide() {
      this.$refs.mySwiper.swiper.slideNext();
    },
  },
};
</script>
<style lang="less" scoped>
@import url(./aa.less);

@media (max-width: 750px) {
}
</style>
