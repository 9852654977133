export const h = {
  lang: '简体中文',
  title: {
    text: "Sign in",
  },
  section1: {
    text: "全球首个Web3",
    text1: "基于JuCoin、JuCoin Labs、JuChain生态系统打造",
    text2: "立即购买仅需1399美元",
  },
  section2: {
    text: "100亿+",
    text1: "美元交易量",
    text2: "全球用户",
    text3: "日活用户",
    text4: "覆盖国家",
  },
  section3: {
    text: "JuOne核心模块及",
    text1: "功能介绍",
    text2: "完善的上市规割及经济模型",
    text3: "Comprehensive listing regulations and economic models",
    text4: "丰厚的前期参与奖励",
    text5: "Generous early participation rewards",
    text6: "持续社区活动及技术骇客马拉松活动",
    text7: "Continuous community activities and technology hacking marathon events",
    text8: "丰富的Web3应用程式预装",
    text9: "Rich Web3 application pre installed",
  },
  section4: {
    text: "JuOne",
    text1: "介绍",
    text2: "原生多链支持",
    text3: "广告与任务激励系统",
    text4: "JUStore应用商店",
    text5: "多场景协同功能",
    text6: "可信硬体及区块链网路",
    text7: "AI智能服务",
    text8: "DEPIN网路支持",
    text9: "商业专案发射器",
    text10: "数字身份舆隐私管理",
  },
  section5: {
    text: "以Web3手机为",
    text1: "核心载体",
    text2: "打造全球领先的Web3多方共赢生态",
  },
  section6: {
    text: "JuOne发展规划",
    text1: "2024年 ",
    text2: "Q4-2025年Q1",
    text3: "集中布局束南亚、北美、欧洲等Web3热点区域完成第一阶段销售10万台的日标与BNB Chain、Solana、Polygon等主流公链达成合作上线JuOne平豪币",
    text4: "2025年",
    text5: "Q2-Q4",
    text6: "与DEPINex专案展开合作完成第二阶段销售100万壹的目标在东南平、欧洲和北美建立区域运营中心搭建Web3物联网生熊",
    text7: "2026年",
    text8: "Q1-Q2",
    text9: "推出Web3应用商城支持更多DApp接入JuOne生熊推动更多公链的接入，形成多链生熊的全面互通骏布JuOne手机2.0版本",
    text10: "2026年",
    text11: "Q3-Q4",
    text12: "支持更多DEPINex专案的接入生熊应用扩展,富用户在Web3中的增值体验JuOne手机进入超过50个国家市场,服务用户超3000万推动更多公的接入形成多链生能的全面互通",
  },

  foot: {
    text: "服务条款",
    text1: "隐私政策",
    text2: "Cookie 政策",
  },
};